import React from 'react'

import Footer from '../components/Footer';
import Navbar from '../components/Navbar.jsx';

const NativeApp = () => {
  return (
    <div>
        <Navbar />
        <div class="container my-24 px-6 mx-auto">
        <section class="mb-32 text-gray-800">
            <div class="flex flex-wrap items-center">
            <div class="grow-0 shrink-0 basis-auto w-full lg:w-4/12 mb-6 md:mb-0 px-3">
                <h2 class="text-3xl font-medium title-font mb-6">
                Native Mobile-App Development
                </h2>
                <p class="text-gray-500 mb-12">
                    Our team of mobile app developers helps in streamlining the operations through mobile, thus, welcoming higher revenue and increased customer base. We ensure to bring value through our custom mobile app development to the ever-demanding customers with a modern outlook.
                </p>
            </div>
            <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 mb-6 mb-md-0 px-3">
                <div class="flex flex-wrap">
                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Design and develop native mobile apps that are optimized for specific platforms and devices</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Integrate the app with device-specific features and functionality, such as cameras, GPS, and push notifications</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Test and debug the app to ensure that it functions correctly and performs well on the target platform</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Collaborate with clients to understand their needs and develop solutions that meet their specific goals</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class="mb-1 font-medium title-font">Use a variety of tools and technologies to create high-quality and effective mobile apps</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                        <div class="shrink-0 mt-1">
                            <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                            </path>
                            </svg>
                        </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class="mb-1 font-medium title-font">Stay up-to-date on the latest mobile app development trends and best practices to ensure that the solutions we deliver are of the highest quality. </p>
                    </div>
                    </div>
                </div>

                </div>
            </div>
                <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://www.newagesmb.com/images/2022/03/Native-Mobile-App-Development.png"></img>
                </div>
            </div>
        </section>
        </div>
        <Footer />
    </div>
  );
}

export default NativeApp;
