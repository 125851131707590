import React from 'react'

import Footer from '../components/Footer';
import Navbar from '../components/Navbar.jsx';

const graphicdesign = () => {
  return (
    <div>
        <Navbar />
        <div class="container my-24 px-6 mx-auto">
        <section class="mb-32 text-gray-800">
            <div class="flex flex-wrap items-center">
            <div class="grow-0 shrink-0 basis-auto w-full lg:w-4/12 mb-6 md:mb-0 px-3">
                <h2 class="text-3xl font-medium title-font mb-6">
                Graphic Design
                </h2>
                <p class="text-gray-500 mb-12">
                Our graphic design services include creating visual concepts, using computer software to design layouts, and producing visual materials such as logos, brochures, business cards, and marketing materials that effectively communicate our clients' messages and brand identity.
                </p>
            </div>
            <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 mb-6 mb-md-0 px-3">
                <div class="flex flex-wrap">
                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Develop and design visual elements for use in marketing and advertising materials</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Create custom graphics for use on websites and social media platforms</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Design layouts for print and digital media, such as magazines, billboards, and advertisements</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class=" mb-1 font-medium title-font">Collaborate with clients to understand their vision and create designs that meet their specific needs and goals</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                    <div class="shrink-0 mt-1">
                        <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                        </path>
                        </svg>
                    </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class="mb-1 font-medium title-font">Use software such as Adobe Creative Suite to create and refine designs</p>
                    </div>
                    </div>
                </div>

                <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 mb-12">
                    <div class="flex">
                        <div class="shrink-0 mt-1">
                            <svg class="w-4 h-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                            </path>
                            </svg>
                        </div>
                    <div class="grow ml-4">
                        <p style={{fontSize: "13px"}} class="mb-1 font-medium title-font">Stay up-to-date on design trends and best practices to ensure high-quality and effective designs</p>
                    </div>
                    </div>
                </div>

                </div>
            </div>
                <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://images.unsplash.com/photo-1626785774573-4b799315345d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z3JhcGhpYyUyMGRlc2lnbnxlbnwwfHwwfHw%3D&w=1000&q=80"></img>
                </div>
            </div>
        </section>
        </div>
        <Footer />
    </div>
  );
}

export default graphicdesign;
